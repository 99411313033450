// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ACr2buUKNFcN5bpZOqAr{--columm-width: 245px;display:flex;align-items:stretch;min-height:500px;padding:20px;gap:18px;background:#4d0d8d;border-radius:8px;font-size:13px;overflow-x:scroll}.ACr2buUKNFcN5bpZOqAr .hSSGghmXEr1vEpoB9udd{display:flex;align-items:center;justify-content:center;gap:8px;min-width:var(--columm-width);width:var(--columm-width);height:40px;align-self:flex-start;color:#fff;background:rgba(255,255,255,.15);border-radius:6px}.ACr2buUKNFcN5bpZOqAr .hSSGghmXEr1vEpoB9udd svg{width:13px}.ACr2buUKNFcN5bpZOqAr .hSSGghmXEr1vEpoB9udd:hover,.ACr2buUKNFcN5bpZOqAr .hSSGghmXEr1vEpoB9udd:focus{background:rgba(255,255,255,.1)}", "",{"version":3,"sources":["webpack://./src/components/board/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CAEA,YAAA,CACA,mBAAA,CACA,gBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAEA,6BAAA,CACA,yBAAA,CACA,WAAA,CACA,qBAAA,CACA,UAAA,CACA,gCAAA,CACA,iBAAA,CAEA,gDACE,UAAA,CAGF,oGAEE,+BAAA","sourcesContent":[".board {\n  --columm-width: 245px;\n\n  display: flex;\n  align-items: stretch;\n  min-height: 500px;\n  padding: 20px;\n  gap: 18px;\n  background: #4d0d8d;\n  border-radius: 8px;\n  font-size: 13px;\n  overflow-x: scroll;\n\n  .addColumnButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n\n    min-width: var(--columm-width);\n    width: var(--columm-width);\n    height: 40px;\n    align-self: flex-start;\n    color: white;\n    background: rgba(255, 255, 255, 0.15);\n    border-radius: 6px;\n\n    svg {\n      width: 13px;\n    }\n\n    &:hover,\n    &:focus {\n      background: rgba(255, 255, 255, 0.1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"board": "ACr2buUKNFcN5bpZOqAr",
	"addColumnButton": "hSSGghmXEr1vEpoB9udd"
};
export default ___CSS_LOADER_EXPORT___;
