// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tbqQvBytJK8tCxELUwo9{background:none;border-radius:.3em;padding:.2em !important;color:#c1cfde}.tbqQvBytJK8tCxELUwo9:hover,.tbqQvBytJK8tCxELUwo9:focus{color:#000}.tbqQvBytJK8tCxELUwo9 svg{width:1em;height:1em}", "",{"version":3,"sources":["webpack://./src/components/icon-button/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA,CACA,uBAAA,CAEA,aAAA,CAEA,wDAEE,UAAA,CAGF,0BACE,SAAA,CACA,UAAA","sourcesContent":[".wrapper {\n  background: none;\n  border-radius: 0.3em;\n  padding: 0.2em !important;\n\n  color: #c1cfde;\n\n  &:hover,\n  &:focus {\n    color: black;\n  }\n\n  svg {\n    width: 1em;\n    height: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "tbqQvBytJK8tCxELUwo9"
};
export default ___CSS_LOADER_EXPORT___;
