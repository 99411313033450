// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rhi5jFW9wB1YlL8vpGXh{flex:1;font-weight:600;border:none;background:none;font-size:13px;font-family:var(--font-family);padding:0;line-height:1.5;text-align:start;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/components/board/column/editable-column-title/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CACA,8BAAA,CACA,SAAA,CACA,eAAA,CAEA,gBAAA,CAEA,iBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".wrapper {\n  flex: 1;\n  font-weight: 600;\n  border: none;\n  background: none;\n  font-size: 13px;\n  font-family: var(--font-family);\n  padding: 0;\n  line-height: 1.5;\n\n  text-align: start;\n\n  overflow-x: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "rhi5jFW9wB1YlL8vpGXh"
};
export default ___CSS_LOADER_EXPORT___;
