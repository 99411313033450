// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aF7wfPwIY4WWlcHy5nof{cursor:pointer;padding:10px;gap:10px;background:#fff;border:1px solid #dde7f0;box-shadow:var(--shadow-1);border-radius:6px;position:relative;overflow:hidden;user-select:none;-webkit-user-select:none}.aF7wfPwIY4WWlcHy5nof._7i85GHwNOocsS4m_tyhA{cursor:grabbing}@media screen and (min-width: 768px){.aF7wfPwIY4WWlcHy5nof:hover button{display:block}.aF7wfPwIY4WWlcHy5nof button{display:none}}", "",{"version":3,"sources":["webpack://./src/components/board/card/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,YAAA,CACA,QAAA,CAEA,eAAA,CAEA,wBAAA,CAEA,0BAAA,CACA,iBAAA,CACA,iBAAA,CACA,eAAA,CAEA,gBAAA,CACA,wBAAA,CAEA,4CACE,eAAA,CAIJ,qCAEI,mCACE,aAAA,CAGF,6BACE,YAAA,CAAA","sourcesContent":[".wrapper {\n  cursor: pointer;\n  padding: 10px;\n  gap: 10px;\n\n  background: white;\n\n  border: 1px solid #dde7f0;\n\n  box-shadow: var(--shadow-1);\n  border-radius: 6px;\n  position: relative;\n  overflow: hidden;\n\n  user-select: none;\n  -webkit-user-select: none;\n\n  &.showHandCursor {\n    cursor: grabbing;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .wrapper {\n    &:hover button {\n      display: block;\n    }\n\n    button {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "aF7wfPwIY4WWlcHy5nof",
	"showHandCursor": "_7i85GHwNOocsS4m_tyhA"
};
export default ___CSS_LOADER_EXPORT___;
