// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lJYOt5M0ZrVreI9e_7Iu{flex:1;height:40px;display:flex;align-items:center;gap:10px;padding-bottom:4px;font-size:21px}.lJYOt5M0ZrVreI9e_7Iu .iH7Hf0NvNsNpjA4_cYX9{font-family:var(--font-family);font-weight:700;font-size:21px;line-height:21px;color:#000;background:none;padding-left:10px}.lJYOt5M0ZrVreI9e_7Iu .iH7Hf0NvNsNpjA4_cYX9.uFnIwoJ1mKMbAfwQOg1D{color:#91a5ba}.lJYOt5M0ZrVreI9e_7Iu .iH7Hf0NvNsNpjA4_cYX9.X4uTlMQig_IX0FQsoQmg{border:1px solid #dde7f0;border-radius:6px;outline:none}.lJYOt5M0ZrVreI9e_7Iu .iH7Hf0NvNsNpjA4_cYX9.X4uTlMQig_IX0FQsoQmg::placeholder{color:#91a5ba;opacity:1}@media screen and (min-width: 768px){.cG6k89oW1mAcEergCn5F{display:none}.lJYOt5M0ZrVreI9e_7Iu:hover .cG6k89oW1mAcEergCn5F{display:block}}", "",{"version":3,"sources":["webpack://./src/components/board-title/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CAEA,4CACE,8BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAEA,iEACE,aAAA,CAGF,iEACE,wBAAA,CACA,iBAAA,CACA,YAAA,CAEA,8EACE,aAAA,CACA,SAAA,CAMR,qCACE,sBACE,YAAA,CAKE,kDACE,aAAA,CAAA","sourcesContent":[".wrapper {\n  flex: 1;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding-bottom: 4px;\n  font-size: 21px;\n\n  .title {\n    font-family: var(--font-family);\n    font-weight: 700;\n    font-size: 21px;\n    line-height: 21px;\n    color: black;\n    background: none;\n    padding-left: 10px;\n\n    &.empty {\n      color: #91a5ba;\n    }\n\n    &.titleInput {\n      border: 1px solid #dde7f0;\n      border-radius: 6px;\n      outline: none;\n\n      &::placeholder {\n        color: #91a5ba;\n        opacity: 1;\n      }\n    }\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .edit {\n    display: none;\n  }\n\n  .wrapper {\n    &:hover {\n      .edit {\n        display: block;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "lJYOt5M0ZrVreI9e_7Iu",
	"title": "iH7Hf0NvNsNpjA4_cYX9",
	"empty": "uFnIwoJ1mKMbAfwQOg1D",
	"titleInput": "X4uTlMQig_IX0FQsoQmg",
	"edit": "cG6k89oW1mAcEergCn5F"
};
export default ___CSS_LOADER_EXPORT___;
