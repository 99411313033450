// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u1rpG35hG1k76XLfBVIA{border:none;background:none;font-size:13px;font-weight:500;line-height:1.5;font-family:var(--font-family);width:100%;resize:none;padding:2px;min-height:19.5px}.PbymRh8nQvStQjhORI6g{font-size:13px !important;position:absolute;right:4px;top:4px}", "",{"version":3,"sources":["webpack://./src/components/board/card/card-content/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,8BAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBACE,yBAAA,CACA,iBAAA,CACA,SAAA,CACA,OAAA","sourcesContent":[".wrapper {\n  border: none;\n  background: none;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 1.5;\n  font-family: var(--font-family);\n  width: 100%;\n  resize: none;\n  padding: 2px;\n  min-height: 19.5px;\n}\n\n.deleteButton {\n  font-size: 13px !important;\n  position: absolute;\n  right: 4px;\n  top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "u1rpG35hG1k76XLfBVIA",
	"deleteButton": "PbymRh8nQvStQjhORI6g"
};
export default ___CSS_LOADER_EXPORT___;
